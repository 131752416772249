export const verifyRecaptcha = async ({ token }) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_VERIFY_RECAPTCHA}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    });
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error(error);
    return false;
  }
};
