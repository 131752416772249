// Filename: FeedbackSection.js

import React from "react";
import { Box, Button, Alert } from "@mui/material";
import correctChime from "audio/correctChime.mp3";
import incorrectChime from "audio/incorrectChime.mp3";
import { notifyFailure } from "toastNotifications";

const FeedbackSection = ({
  selectedAnswer,
  setSelectedAnswer,
  handleNext,
  feedback,
  setFeedback,
  singleQuestion,
  currentIndex,
  totalQuestions,
  handleSubmitAnswer,
  submittingAnswer,
  correct = false,
}) => {
  const isMobile = window.innerWidth < 600;
  const Feedback = () => {
    if (selectedAnswer === null) {
      // show placeholder if no answer selected yet
      return <div></div>;
    }
    if (feedback === "" || correct === null) {
      // show placeholder if no feedback present yet
      return <div></div>;
    } else {
      // show feedback if present
      return (
        <Box className="feedback-alert-container">
          {correct ? (
            <Alert className="feedback-alert" severity="success">
              {feedback}
            </Alert>
          ) : (
            <Alert className="feedback-alert" severity="error">
              {feedback}
            </Alert>
          )}
        </Box>
      );
    }
  };
  const ContinueButton = () => {
    return (
      <Button
        // show Continue button if feedback is displayed
        type={"button"}
        className="continue-button"
        variant="contained"
        size="small"
        onClick={() => {
          // when you click continue, selected answer becomes null
          // and feedback is cleared
          setFeedback("");
          setSelectedAnswer(null);
          handleNext();
        }}
      >
        {currentIndex < totalQuestions - 1 ? "Continue" : "Finish"}
      </Button>
    );
  };

  const CheckButton = () => {
    return (
      <Button
        className="check-answer-button"
        type={"button"}
        variant="contained"
        size="small"
        onClick={() => {
          handleSubmitAnswer(selectedAnswer)
            .then(() => {
              const chime = correct ? new Audio(correctChime) : new Audio(incorrectChime);
              chime.play();
            })
            .catch((e) => {
              console.error("Error submitting answer", e);
              notifyFailure("Error submitting answer");
            });
        }}
        // disable the Check answer button if no answer is selected
        disabled={selectedAnswer === null || submittingAnswer || JSON.stringify(selectedAnswer) === "{}"}
      >
        Check
      </Button>
    );
  };

  const RetryButton = () => {
    return (
      <Button
        // show check answer button if feedback not currently displayed
        type={"button"}
        className="retry-answer-button"
        variant="contained"
        size="small"
        onClick={() => {
          setFeedback("");
          setSelectedAnswer(null);
        }}
      >
        Try Again
      </Button>
    );
  };

  const CheckAndRetry = () => {
    // these buttons are mutually exclusive. only one displays at a time.
    // retry only shows for singleQuestion instances
    return (
      <Box className="check-and-retry-container">
        {feedback !== "" && <RetryButton></RetryButton>}
        {feedback === "" && <CheckButton></CheckButton>}
      </Box>
    );
  };

  const CheckAndContinue = () => {
    // these buttons are mutually exclusive. only one displays at a time.
    // retry only shows for singleQuestion instances
    return (
      <Box className="check-and-continue-container">
        {feedback !== "" && <ContinueButton></ContinueButton>}
        {feedback === "" && <CheckButton></CheckButton>}
      </Box>
    );
  };
  return (
    <Box
      className="feedback-section"
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Feedback></Feedback>
      {!singleQuestion && <CheckAndContinue></CheckAndContinue>}
      {singleQuestion && <CheckAndRetry></CheckAndRetry>}
    </Box>
  );
};
export default FeedbackSection;
