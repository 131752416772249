import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Page from "../components/Page";
import { MyPlanDetails, MyCurrentMonthUsage } from "./components";
import { useSub } from "contexts";

const PlanAndUsage = () => {
  const [price, setPrice] = useState(null);
  const theme = useTheme();
  const { planDetails } = useSub();

  useEffect(() => {
    if (planDetails) {
      if (planDetails.monthly_price_dollars > 0) {
        setPrice(`$${planDetails.monthly_price_dollars} per month`);
      } else {
        setPrice("Free");
      }
    }
  }, [planDetails]);

  if (!planDetails || !price) {
    return null;
  }
  return (
    <Page>
      <Box>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Typography variant="h4" fontWeight={200} textTransform={"uppercase"}>
            My Plan & Usage
          </Typography>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          <MyPlanDetails planDetails={planDetails} />
        </Box>
        <Box marginTop={4}>
          <MyCurrentMonthUsage planDetails={planDetails} />
        </Box>
      </Box>
    </Page>
  );
};

export default PlanAndUsage;
