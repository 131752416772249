import React, { useState, useEffect } from "react";
import Container from "components/Container";
import { useTheme } from "@mui/material/styles";
import { Card, Typography, Box, Button, Chip } from "@mui/material";
import "./index.css";
import { Question, ProgressBar, FinishScreen } from "components/Question";
import { Article, LightbulbCircle, Link, UploadFile, Google, Download, ReadMore } from "@mui/icons-material";
import { notifyFailure } from "toastNotifications";

const Quiz = ({ quiz }) => {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  const [showFinishScreen, setShowFinishScreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [resetting, setResetting] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
    setProgress(0);
  }, []);

  useEffect(() => {
    if (quiz.questions && quiz.questions.length > 0) {
      setTotalQuestions(quiz.questions.length);
      setCurrentQuestion(quiz.questions[currentIndex]);
    }
  }, [quiz.questions]);

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handleReset = () => {
    setResetting(true);
    setCurrentIndex(0);
    setTotalCorrectAnswers(0);
    setShowFinishScreen(false);
  };

  useEffect(() => {
    if (quiz.questions && quiz.questions.length > 0) {
      // Wait for the slide-out animation to finish
      setTimeout(() => {
        setCurrentQuestion(quiz.questions[currentIndex]);
        setProgress((currentIndex / totalQuestions) * 100);
        setIsAnimating(false);
        // if starting over from finish screen, we need to auto
        // scroll back to the quiz because it gets pushed down
        // below the screen on finish
        if (currentIndex == 0 && resetting) {
          setTimeout(() => {
            setResetting(false);
            document.getElementById("quiz-card").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }, 300);
        }
      }, 500); // Adjust the delay to match your animation duration
    }

    if (totalQuestions > 0 && currentIndex === totalQuestions) {
      setShowFinishScreen(true);
    }

    // Only animate if we're not on the first question
    if (currentIndex !== 0) {
      setIsAnimating(true);
    }
  }, [currentIndex]);

  if (quiz.questions && quiz.questions.length === 0) {
    return null;
  }

  return (
    <Card
      id={"quiz-card"}
      bgcolor={theme.palette.primary.main}
      color={theme.palette.background.paper}
      sx={{
        marginTop: "1rem",
        marginBottom: "1rem",
        boxShadow: 8,
        minWidth: {
          xs: "100%",
          sm: "100%",
        },
      }}
    >
      <Container paddingY={3} maxWidth={"95%"} minHeight={"350px"}>
        {currentQuestion && (
          <Box marginTop={2} marginBottom={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {currentQuestion.generated_from.topic && (
                <Chip
                  color="primary"
                  icon={<LightbulbCircle color="primary" />}
                  label={currentQuestion.generated_from.topic}
                />
              )}
              {currentQuestion.generated_from.wiki_article && (
                <Chip
                  color="primary"
                  icon={<Article color="primary" />}
                  label={currentQuestion.generated_from.wiki_article.title}
                />
              )}
              {currentQuestion.generated_from.upload_pdf && (
                <Chip
                  color="primary"
                  icon={<UploadFile color="primary" />}
                  label={currentQuestion.generated_from.upload_pdf.split("/")[1]}
                />
              )}
            </Box>

            <Typography>{quiz.questions.length} Questions</Typography>
            {currentQuestion.generated_from === "upload_pdf" && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={<Download />}
                onClick={() => {
                  // download PDF here TODO
                  notifyFailure("Feature not implemented yet");
                }}
              >
                Download PDF
              </Button>
            )}
            {currentQuestion.generated_from.topic && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                LinkComponent={"a"}
                startIcon={<Google />}
                target="_blank"
                href={`https://www.google.com/search?q=${encodeURIComponent(currentQuestion.generated_from.topic)}`}
              >
                Google this topic
              </Button>
            )}
            {currentQuestion.generated_from.wiki_article && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                LinkComponent={"a"}
                target="_blank"
                startIcon={<ReadMore />}
                href={quiz.wiki_article.url}
              >
                Visit Wikipedia Article
              </Button>
            )}
          </Box>
        )}
        <ProgressBar variant="determinate" value={progress} theme={theme} />
        {!showFinishScreen && currentQuestion && (
          <Question
            isAnimating={isAnimating}
            question={currentQuestion}
            handleNext={handleNext}
            totalCorrectAnswers={totalCorrectAnswers}
            setTotalCorrectAnswers={setTotalCorrectAnswers}
            currentIndex={currentIndex}
            totalQuestions={totalQuestions}
            singleQuestion={false}
          />
        )}
        {showFinishScreen && (
          <FinishScreen
            totalCorrectAnswers={totalCorrectAnswers}
            totalQuestions={totalQuestions}
            handleReset={handleReset}
          ></FinishScreen>
        )}
      </Container>
    </Card>
  );
};

export default Quiz;
