import React from "react";
import { Box } from "@mui/material";
import DroppableBlank from "./DroppableBlank";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

// Custom renderer to replace `p` with `span`
const components = {
  p: ({ node, ...props }) => <span {...props} />,
};

const FIBQuestionText = ({ questionText, selectedAnswer, onClickFilledBlank = () => {} }) => {
  const parts = questionText.split(/(<<\d+>>)/);

  return (
    <Box
      sx={{
        padding: 1,
        marginBottom: 1,
      }}
    >
      {parts.map((part, index) => {
        const match = part.match(/<<(?<index>\d+)>>/);
        if (match) {
          return (
            <Box
              key={index}
              sx={{
                display: "inline",
                "& span": {
                  lineHeight: 3,
                },
              }}
            >
              <DroppableBlank
                onClickFilledBlank={onClickFilledBlank}
                index={match.groups.index}
                selectedAnswer={selectedAnswer}
              />
            </Box>
          );
        } else {
          return (
            <Box
              key={index}
              sx={{
                display: "inline",
                "& span": {
                  lineHeight: 3,
                },
              }}
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} components={components}>
                {part}
              </ReactMarkdown>
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default FIBQuestionText;
