import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { useNav } from "contexts";
import { DataUsage, Widgets } from "@mui/icons-material";

const MonthlyLimitsReached = ({ planDetails, currentMonthUsage }) => {
  // use MUI components for styling: Box, Typography, Button
  const { navigate } = useNav();
  const limitsText = `${planDetails.monthly_limits.quizzes} quizzes or ${planDetails.monthly_limits.questions} questions`;
  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} marginBottom={5}>
        <Typography variant="caption1" gutterBottom textAlign={"center"} textTransform={"uppercase"} fontWeight={200}>
          Monthly Limits Reached
        </Typography>

        {
          // cannot upgrade if on Voyager plan; show different message depending on plans
          !planDetails.name.includes("Voyager") ? (
            <Typography variant="body1" gutterBottom textAlign={"center"}>
              Looks like you've been busy! You've reached your monthly limits ({limitsText}) for the {planDetails.name}.
              Please upgrade your plan to continue. Alternatively, you can wait for your limits to reset on the{" "}
              <strong>first of next month</strong>.
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom textAlign={"center"}>
              Looks like you've been busy! You've reached your monthly limits ({limitsText}) for the {planDetails.name}.
              You will need to wait for your plan limits to reset on the <strong>first of next month</strong>.
            </Typography>
          )
        }
        <Box display={"flex"} justifyContent={"center"} flexDirection={"row"} marginTop={2} gap={2}>
          {!planDetails.name.includes("Voyager") && (
            <Button
              onClick={() => navigate("/plans")}
              variant="contained"
              color="primary"
              sx={{ width: "fit-content" }}
            >
              <Widgets sx={{ marginRight: 1 }} />
              Upgrade
            </Button>
          )}
          <Button
            onClick={() => navigate("/account-plan")}
            variant="outlined"
            color="secondary"
            sx={{ width: "fit-content" }}
          >
            <DataUsage sx={{ marginRight: 1 }} />
            View My Plan and Usage
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default MonthlyLimitsReached;
