import React, { useState } from "react";
import { useNav } from "contexts";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "components/Container";
import { Topbar, Sidebar, Footer } from "./components";

const Main = ({ children, colorInvert = false, bgcolor = "rgba(255, 255, 255, 0.8)" }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const { pages } = useNav();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true, threshold: 38,
  });

  if (!pages) {
    return null;
  }

  return (<Box>
      <AppBar
        position={"sticky"}
        sx={{
          top: 0, backgroundColor: trigger ? theme.palette.background.paper : bgcolor, zIndex: 1040,
        }}
        elevation={1}
      >
        <Container paddingY={1}>
          <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} colorInvert={trigger ? false : colorInvert} />
        </Container>
      </AppBar>
      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" pages={pages} />
      <main>
        {children}
        <Divider />
      </main>
      <Container paddingY={4}>
        <Footer />
      </Container>
    </Box>);
};

Main.propTypes = {
  children: PropTypes.node, colorInvert: PropTypes.bool, bgcolor: PropTypes.string,
};

export default Main;
