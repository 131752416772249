import React, { useEffect, useState } from "react";
import { useNav, useSub } from "contexts";
import { useParams } from "react-router-dom";
import { getCheckoutSessionStatus } from "api/stripe";
import { Container } from "components";
import { Box, Typography, Button, Grid, Divider } from "@mui/material";

const CheckoutReturn = () => {
  const [status, setStatus] = useState(null);
  const { navigate } = useNav();
  const [customerEmail, setCustomerEmail] = useState("");
  const { sessionId, plan } = useParams();
  const { updatePlanDetailsState, planDetails } = useSub();

  useEffect(() => {
    getCheckoutSessionStatus({
      sessionId,
      plan,
    }).then((data) => {
      if (data.status === "complete") {
        updatePlanDetailsState();
      }
      setStatus(data.status);
      setCustomerEmail(data.customer_email);
    });
  }, []);

  if (status === "open") {
    navigate("/subscribe");
  }

  if (status === "complete" && planDetails) {
    return (
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" flexDirection={"column"} maxWidth={"sm"}>
              <Typography
                variant="h4"
                align="left"
                color="text.primary"
                marginBottom={1}
                textAlign={"left"}
                sx={{ fontWeight: 700 }}
              >
                Payment Complete!
              </Typography>

              <Typography variant="p" color={"text.primary"} marginBottom={2} textAlign={"left"}>
                You're now subscribed to the <strong>StudyRocket {planDetails.name}</strong>! You have full access to
                generate quizzes using AI about any topic, article, or document you wish. Have fun studying! You should
                receive a receipt for your records shortly at the email address you provided during checkout (
                {customerEmail}).
              </Typography>

              <Divider
                sx={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              />

              <Button
                variant="contained"
                color="secondary"
                sx={{ maxWidth: "fit-content" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Back to Homepage
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return null;
};

export default CheckoutReturn;
