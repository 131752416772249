// Filename: FIBQuestion.js

import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { FIBBlankFillerChoices, FIBQuestionText } from "./components";
import { DragDropContext } from "react-beautiful-dnd";
import FeedbackSection from "components/Question/FeedbackSection";
import { notifySuccess } from "toastNotifications";
import shuffle from "util/shuffle";
import frontendSubmitQuizQuestion from "util/frontendSubmitQuizQuestion";

const FIBQuestion = ({
  question,
  handleNext,
  singleQuestion,
  totalQuestions,
  currentIndex,
  totalCorrectAnswers,
  setTotalCorrectAnswers,
}) => {
  /* question structure
   {
    "id": "82ef90f6-cb3b-4837-a263-f7e78798ac00",
    "quiz": "41eaf11f-ed98-49ac-9ce0-2f2ff181536b",
    "q": "The comedian known for his 'blue comedy' style is <<0>>, and the famous comedy festival held in Edinburgh is called <<1>>.",
    "ch": {
        "0": {
            "cor": "George Carlin",
            "inc": [
                "Eddie Murphy",
                "Dave Chappelle"
            ]
        },
        "1": {
            "cor": "Edinburgh Festival Fringe",
            "inc": [
                "Montreal Comedy Festival",
                "Just for Laughs"
            ]
        }
    },
    "t": "fib",
    "created_at": "2024-09-15 20:30:57",
    "generated_from": {
        "topic": "stand up comedy",
        "wiki_article": null,
        "upload_pdf": null
    }
}
  */

  const [allBlanksFilled, setAllBlanksFilled] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [allChoices, setAllChoices] = useState(null);
  const [correct, setCorrect] = useState(false);

  const resetBlanks = () => {
    setSelectedAnswer({
      ...Object.keys(question.ch).reduce((acc, blank) => {
        acc[blank] = "";
        return acc;
      }, {}),
    });
    setAllChoices(
      shuffle(Object.values(question.ch).reduce((acc, choice) => acc.concat([choice.cor, ...choice.inc]), [])).map(
        (choice, index) => `${index}-${choice}`,
      ),
    );
  };

  useEffect(() => {
    resetBlanks(); // re-initialize the blanks when the question changes
  }, [question.id]);

  // when selectedAnswer changes, check if all blanks are filled
  useEffect(() => {
    if (!selectedAnswer) return;
    const allFilled = Object.values(selectedAnswer).every((answer) => answer !== "");
    setAllBlanksFilled(allFilled);

    const correct = Object.keys(selectedAnswer).every((blank) => selectedAnswer[blank] === question.ch[blank].cor);
    setCorrect(correct);
  }, [selectedAnswer]);

  const [feedback, setFeedback] = useState("");
  const [submittingAnswer, setSubmittingAnswer] = useState(false);

  const onClickFilledBlank = (blankIndex) => {
    // move the selected answer back to end of allChoices array; empty the blank
    const choiceText = selectedAnswer[blankIndex];
    const choiceIndex = allChoices.length;

    setAllChoices((prev) => [...prev, `${choiceIndex}-${choiceText}`]);
    setSelectedAnswer((prev) => ({
      ...prev,
      [blankIndex]: "",
    }));
  };

  const onDragEnd = (result) => {
    const { destination } = result;
    const _split = result.draggableId.split("-");
    // choice text is everything after the first - in the draggableId - dont just use split: e.g., "single sign-on" should not become "single sign"
    const choiceText = result.draggableId.substring(result.draggableId.indexOf("-") + 1);
    const choiceIndex = _split[0];

    if (!destination) return;

    // if user is explicitly moving the choice into a blank, allow switching out of answers.
    // if destination already populated, move the text currently in the destination to the source
    //
    // copy current allChoices array
    let newAllChoices = [...allChoices];
    if (selectedAnswer[destination.droppableId]) {
      // get the answer text already provided in the destination blank
      const destExistingAnswerText = document.querySelector(
        `[data-rbd-droppable-id="${destination.droppableId}"]`,
      ).textContent;
      // format the text to match the draggableId format. Reuse the index from the draggableId
      const recycleAnswer = `${choiceIndex}-${destExistingAnswerText}`;
      // add the recycled answer to the allChoices array
      newAllChoices.push(recycleAnswer);
    }
    // remove selected choice from allChoices
    newAllChoices = newAllChoices.filter((c) => c !== result.draggableId);

    setAllChoices(newAllChoices);

    setSelectedAnswer((prev) => ({
      ...prev,
      [destination.droppableId]: choiceText,
    }));
  };

  const animateBlankFill = async ({ draggableChoiceId, droppableBlankIndex }) => {
    return new Promise((resolve, reject) => {
      const draggable = document.querySelector(`[data-rbd-draggable-id="${draggableChoiceId}"]`);
      const destination = document.querySelector(`[data-rbd-droppable-id="${droppableBlankIndex}"]`);
      if (!draggable) {
        console.error(`No element found with draggableId: ${draggableChoiceId}`);
        return;
      }
      if (!destination) {
        console.error(`No element found with blankIndex: ${droppableBlankIndex}`);
        return;
      }
      const draggableRect = draggable.getBoundingClientRect();
      const destinationRect = destination.getBoundingClientRect();
      const dx = destinationRect.x - draggableRect.x;
      const dy = destinationRect.y - draggableRect.y;
      const animation = draggable.animate(
        [{ transform: `translate(0px, 0px)` }, { transform: `translate(${dx}px, ${dy}px)` }],
        {
          duration: 300,
          iterations: 1,
        },
      );
      animation.onfinish = () => resolve();
      animation.onerror = (error) => reject(error);
    });
  };

  const onClickAnswerChoice = ({
    choice, // string: index-choice (e.g. '0-correct answer')
  }) => {
    if (allBlanksFilled) {
      notifySuccess("All blanks are filled.");
      return;
    }

    // move to the next blank that is not filled
    const moveToBlank = Object.keys(selectedAnswer).find((blank) => !selectedAnswer[blank]);

    // animate the drag and drop effect automatically
    animateBlankFill({
      draggableChoiceId: choice,
      droppableBlankIndex: moveToBlank,
    })
      .then(() => {
        setSelectedAnswer((prev) => ({
          ...prev,
          [moveToBlank]: choice.substring(choice.indexOf("-") + 1),
        }));
      })
      .then(() => {
        // remove selected choice from allChoices
        setAllChoices((prev) => prev.filter((c) => c !== choice));
      })
      .catch((error) => {
        console.error("Failed to animate blank fill", error);
      });
  };

  const handleSubmitAnswer = async (selectedAnswer) => {
    // only correct if all of the blanks are correctly filled

    return await frontendSubmitQuizQuestion({
      selectedAnswer: selectedAnswer,
      setTotalCorrectAnswers: setTotalCorrectAnswers,
      totalCorrectAnswers: totalCorrectAnswers,
      setFeedback: setFeedback,
      setSubmittingAnswer: setSubmittingAnswer,
      correct: correct,
      question: question,
    });
  };
  return (
    selectedAnswer &&
    allChoices && (
      <Box>
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ marginBottom: 1, padding: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
              <Button variant="outlined" color="secondary" onClick={resetBlanks}>
                Reset
              </Button>
            </Box>
            <FIBQuestionText
              onClickFilledBlank={onClickFilledBlank}
              questionText={question.q}
              selectedAnswer={selectedAnswer}
            />
            <FIBBlankFillerChoices allChoices={allChoices} onClickAnswerChoice={onClickAnswerChoice} />
          </DragDropContext>
        </Box>

        <FeedbackSection
          singleQuestion={singleQuestion}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          handleNext={handleNext}
          feedback={feedback}
          setFeedback={setFeedback}
          totalQuestions={totalQuestions}
          currentIndex={currentIndex}
          handleSubmitAnswer={handleSubmitAnswer}
          submittingAnswer={submittingAnswer}
          correct={correct}
        />
      </Box>
    )
  );
};

export default FIBQuestion;
