import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { Modal as ModalMUI } from "@mui/material";

const Modal = ({
  isOpen = false,
  onClose = () => {},
  bodyText = "Body text",
  buttonText = "Button text",
  titleText = "Title text",

  modalType = "confirmation", // confirmation, alert
  onConfirm = () => {},
  cancelButtonText = "Cancel",
  confirmButtonText = "Confirm",
}) => {
  return (
    <ModalMUI
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          bgcolor: "white",
          border: "1px solid #ccc",
          boxShadow: 3,
          borderRadius: "10px",
          p: 3,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", marginBottom: 2, fontWeight: "bold" }}
        >
          {titleText}
        </Typography>

        <Divider />
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center", marginBottom: 2 }}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />

        {modalType === "confirmation" && (
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
            <Button variant="outlined" onClick={onClose} sx={{ flex: 1 }}>
              {cancelButtonText}
            </Button>
            <Button variant="contained" color="primary" onClick={onConfirm} sx={{ flex: 1 }}>
              {confirmButtonText}
            </Button>
          </Box>
        )}
        {modalType === "alert" && (
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            sx={{ mt: 3, display: "block", margin: "0 auto" }}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </ModalMUI>
  );
};

export default Modal;
