// Path: api/quiz.js

// This file contains the functions that make requests to the backend related to quizzes

import axios from "axios";

export const generateQuiz = async ({
  generateFrom = "upload_pdf", // options: 'upload_pdf', 'topic', 'wiki_article'
  file = null,
  topic = null,
  wiki_article = null,
  question_types = ["multiple_choice"], // options; mlt, fib, tf, oe
  num_questions = 1,
}) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GENERATE_QUIZ}`;
    if (generateFrom === "upload_pdf") {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("generate_from", generateFrom);
      formData.append("question_types", question_types);
      formData.append("num_questions", num_questions);
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        return response.data;
      }
    } else {
      // JSON data
      const response = await axios.post(
        apiUrl,
        {
          generate_from: generateFrom,
          topic: topic,
          question_types: question_types,
          wiki_article: wiki_article,
          num_questions: num_questions,
        },
        {
          headers: { Authorization: `Token ${token}` },
        },
      );
      if (response.status === 201) {
        return response.data;
      }
    }
  } catch (error) {
    console.error("Error generating quiz", error);
    throw error;
  }
};

export const getQuiz = async ({ quizId }) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_QUIZ}`;
    const response = await axios.get(`${apiUrl}${quizId}/`, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting quiz", error);
    throw error;
  }
};

export const getQuizzes = async () => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_QUIZZES}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting quizzes", error);
    throw error;
  }
};

export const submitOpenEndedQuizQuestion = async ({ question_id, answer }) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_SUBMIT_OPEN_ENDED_QUIZ_QUESTION}${question_id}/`;
    const response = await axios.post(
      apiUrl,
      {
        answer,
      },
      {
        headers: { Authorization: `Token ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting open-ended quiz question", error);
    throw error;
  }
};
