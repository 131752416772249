import { getCorrectFeedback, getIncorrectFeedback } from "data/feedbackStatements";

const frontendSubmitQuizQuestion = async ({
  selectedAnswer = {},
  setTotalCorrectAnswers = () => {},
  totalCorrectAnswers = 0,
  setFeedback = () => {},
  setSubmittingAnswer = () => {},
  correct = false,
  question = null,
}) => {
  setSubmittingAnswer(true);
  // answer itself may have specific feedback; if not, use the default feedback

  if (correct) {
    setTotalCorrectAnswers(totalCorrectAnswers + 1);
    setFeedback(selectedAnswer.fb || getCorrectFeedback());
  } else {
    if (question.t === "fib") {
      let feedback = getIncorrectFeedback();
      // add correct answers to the string
      /* {
          "0": {
              "cor": "Edinburgh Fringe",
              "inc": [
                  "Montreal Comedy Festival",
                  "Just for Laughs"
              ]
          },
          "1": {
              "cor": "observational",
              "inc": [
                  "improv",
                  "prop"
              ]
          },
          "2": {
              "cor": "Abbott and Costello",
              "inc": [
                  "Laurel and Hardy",
                  "The Smothers Brothers"
              ]
          }
      } */
      const correctAnswers = Object.keys(question.ch).map((key) => question.ch[key].cor);
      feedback += ` Correct answers: ${correctAnswers.join(", ")}`;
      setFeedback(feedback);
    } else {
      setFeedback(selectedAnswer.fb || getIncorrectFeedback());
    }
  }
  setSubmittingAnswer(false);
  return selectedAnswer;
};

export default frontendSubmitQuizQuestion;
