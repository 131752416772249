import React, { useEffect, useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  InputLabel,
  Button,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSub } from "contexts";
import { generateQuiz, getQuizzes } from "api/quiz";
import { notifyFailure, notifySuccess } from "toastNotifications";
import { LightbulbCircle, UploadFile } from "@mui/icons-material";
import WikipediaAutoComplete from "./WikipediaAutocomplete/WikipediaAutoComplete";

const GenerateQuizForm = ({ setQuizzes }) => {
  const theme = useTheme();
  const { planDetails, currentMonthUsage } = useSub();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [topic, setTopic] = useState("");
  const [wikipediaArticle, setWikipediaArticle] = useState(null);
  const [numQuestions, setNumQuestions] = useState(5);
  const [generateButtonText, setGenerateButtonText] = useState("Generate Quiz");
  const [includeQuestionTypes, setIncludeQuestionTypes] = useState(["mlt"]);
  const [questionTypeOptions, setQuestionTypeOptions] = useState([]);
  const [maxQuestions, setMaxQuestions] = useState(0);

  const questionTypeOptionsFriendlyNames = {
    mlt: "Multiple Choice",
    tf: "True/False",
    fib: "Fill in the Blanks",
    oe: "Open Ended",
  };

  useEffect(() => {
    // question type options and num questions max limit are based on plan
    if (planDetails && currentMonthUsage) {
      let maxQuestions = planDetails.monthly_limits.questions - currentMonthUsage.questions;
      let questionOptions = [];
      if (planDetails.features.multiple_choice) {
        questionOptions.push("mlt");
      }
      if (planDetails.features.true_false) {
        questionOptions.push("tf");
      }
      if (planDetails.features.fill_in_the_blanks) {
        questionOptions.push("fib");
      }
      if (planDetails.features.open_ended) {
        questionOptions.push("oe");
      }
      setQuestionTypeOptions(questionOptions);
      setIncludeQuestionTypes(questionOptions);
      setMaxQuestions(maxQuestions);
    }
  }, [planDetails, currentMonthUsage]);

  const availableOptions = [
    { value: "upload_pdf", label: "My Own Document" },
    { value: "topic", label: "Provide a topic" },
    { value: "wiki_article", label: "Wikipedia Article" },
  ];
  const clearAllValues = () => {
    setFile(null);
    setTopic("");
    setWikipediaArticle(null);
  };
  const [selectedOption, setSelectedOption] = useState(availableOptions[0].value);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    clearAllValues();
  };

  useEffect(() => {
    if (wikipediaArticle) {
      setGenerateButtonText(`Generate Quiz About Wiki Article: ${wikipediaArticle.title}`);
    } else if (file) {
      setGenerateButtonText(`Generate Quiz About ${file.name}`);
    } else if (topic) {
      setGenerateButtonText(`Generate Quiz About ${topic}`);
    } else {
      setGenerateButtonText("Generate Quiz");
    }
  }, [topic, file, wikipediaArticle]);

  return (
    <Box
      marginBottom={3}
      sx={{
        textAlign: "center",
        width: {
          xs: "100%",
          sm: "100%",
          md: "85%",
          lg: "70%",
          xl: "60%",
        },
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold" }} color={theme.palette.primary.main} marginBottom={2}>
        welcome to the quizification station.
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} color={theme.palette.secondary.main} marginBottom={2}>
        let's see if you know your stuff.
      </Typography>
      <Typography variant="body1" color={theme.palette.primary.dark} marginBottom={2}>
        What would you like to be quizzed on?
      </Typography>
      {/* prompt to select options with MUI Select */}
      <FormControl fullWidth>
        <InputLabel size="small" id="quiz-generation-method-label">
          Quiz Generation Method
        </InputLabel>
        <Select
          label="Quiz Generation Method"
          labelId="quiz-generation-method-label"
          value={selectedOption}
          onChange={handleOptionChange}
          fullWidth
        >
          {availableOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Box marginTop={3} width={"lg"} marginX={"auto"}>
          {/* conditional rendering based on selected option */}
          {selectedOption === "upload_pdf" && (
            <Box
              marginBottom={2}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              <UploadFile color="secondary" />
              <TextField
                InputLabelProps={{ shrink: true }}
                type="file"
                accept=".pdf"
                label="Upload PDF"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Box>
          )}
          {selectedOption === "topic" && (
            <Box
              marginBottom={2}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              <LightbulbCircle color="secondary" />
              <TextField
                fullWidth
                label="Topic"
                type="text"
                onChange={(e) => {
                  setTopic(e.target.value);
                }}
              />
            </Box>
          )}

          {selectedOption === "wiki_article" && <WikipediaAutoComplete setWikipediaArticle={setWikipediaArticle} />}
        </Box>
        <Box display={"flex"} justifyContent={"center"} flexDirection={"row"} marginTop={3} gap={3}>
          {/* multi-select for question types */}
          <FormControl sx={{ minWidth: 180, flexBasis: "30%" }}>
            <InputLabel id="question-types-label">Question Types</InputLabel>
            <Select
              labelId="question-types-label"
              multiple
              label="Question Types"
              value={includeQuestionTypes}
              onChange={(event) => {
                setIncludeQuestionTypes(event.target.value);
              }}
              renderValue={(selected) => selected.map((value) => questionTypeOptionsFriendlyNames[value]).join(", ")}
            >
              {questionTypeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={includeQuestionTypes.indexOf(option) > -1} />
                  <ListItemText primary={questionTypeOptionsFriendlyNames[option]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* input field for number of questions */}
          <TextField
            type="number"
            inputProps={{ min: 1, max: maxQuestions }}
            label="# Questions"
            variant="outlined"
            size="large"
            value={numQuestions}
            onChange={(e) => setNumQuestions(e.target.value)}
            sx={{ marginRight: 2, minWidth: 100, flexBasis: "10%", textAlign: "center" }}
          />

          <Button
            fullWidth={false}
            size="large"
            variant="contained"
            color="primary"
            disabled={
              loading ||
              (!file && !topic && !wikipediaArticle) ||
              numQuestions < 1 ||
              numQuestions > maxQuestions ||
              includeQuestionTypes.length === 0
            }
            onClick={() => {
              setLoading(true);
              generateQuiz({
                generateFrom: selectedOption,
                file: file,
                topic: topic,
                wiki_article: wikipediaArticle,
                question_types: includeQuestionTypes,
                num_questions: numQuestions,
              })
                .then((data) => {
                  notifySuccess("Quiz is now generating!");
                  setLoading(false);
                })
                .then(() => {
                  getQuizzes().then((data) => {
                    setQuizzes(data);
                  });
                })
                .catch((e) => {
                  console.error(e);
                  notifyFailure("Error generating quiz");
                  setLoading(false);
                });
            }}
          >
            {generateButtonText}
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default GenerateQuizForm;
