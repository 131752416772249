import React, { useState, useEffect, useRef } from "react";
import { useNav } from "contexts";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NavItem = ({ title, id, items, colorInvert = false, icon }) => {
  const theme = useTheme();
  const { IconMapper } = useNav();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const buttonRef = useRef(null); // Create a ref for the button

  const handleClick = () => {
    setAnchorEl(buttonRef.current); // Use the button ref as the anchor
    setOpenedPopoverId(openedPopoverId === id ? null : id); // Toggle popover
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  const linkColor = colorInvert ? "common.white" : "text.primary";

  return (
    <Box>
      <Button
        ref={buttonRef} // Attach the ref to the button
        size={"small"}
        variant="text"
        fullWidth
        color="secondary"
        aria-describedby={id}
        onClick={handleClick}
        fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
        startIcon={<IconMapper iconName={icon} />}
        endIcon={openedPopoverId === id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {title}
      </Button>

      <Popover
        elevation={3}
        id={id}
        open={openedPopoverId === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiPaper-root": {
            maxWidth: items.length > 12 ? 350 : 250,
            padding: 2,
            marginTop: 2,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={0.5}>
          {items.map((p, i) => (
            <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
              <Button
                component={"a"}
                href={p.href}
                fullWidth
                size="small"
                variant="text"
                startIcon={p.icon ? <IconMapper iconName={p.icon} /> : null}
                sx={{
                  justifyContent: "flex-start",
                  color: activeLink === p.href ? theme.palette.primary.main : theme.palette.text.primary,
                  backgroundColor: activeLink === p.href ? alpha(theme.palette.primary.main, 0.1) : "transparent",
                  fontWeight: activeLink === p.href ? 600 : 400,
                }}
              >
                {p.title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  colorInvert: PropTypes.bool,
  icon: PropTypes.string,
};

export default NavItem;
