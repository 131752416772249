// Path: src/api/subscription.js
// This file contains the functions that make requests to the backend related to subscription

import axios from "axios";

export const getUserPlanDetails = async () => {
  // get the user's plan details
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_USER_PLAN_DETAILS}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const cancelSubscription = async ({ plan }) => {
  // cancel the user's subscription
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CANCEL_SUBSCRIPTION}`;
    const response = await axios.post(
      apiUrl,
      {
        plan: plan,
      },
      {
        headers: { Authorization: `Token ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUserCurrentMonthUsage = async () => {
  // get the user's usage details
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_USER_CURRENT_MONTH_USAGE}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    return false;
  }
};
