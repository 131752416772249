// Login.js

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Grid, Container, TextField, Typography, Alert } from "@mui/material";
import "./Login.css";
import { LoadingButton } from "@mui/lab";
import { useNav, useAuth, useRECAPTCHA } from "contexts";
import { getPrimaryNavigation } from "api/navigation";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { handleLogin, isAuthenticated } = useAuth();
  const { setCurrentPage, setPages, navigate } = useNav();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { recaptchaVerified, setRecaptchaAction } = useRECAPTCHA();

  useEffect(() => {
    setCurrentPage("login");
    setRecaptchaAction("login");
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loginSuccess) {
      getPrimaryNavigation()
        .then((navigation) => {
          setPages(navigation);
          navigate(from); // Move navigation here
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("Failed to fetch navigation data");
        });
    }
  }, [loginSuccess]); // This effect runs when `loginSuccess` changes

  const handleSubmit = async (e) => {
    if (!recaptchaVerified) {
      setErrorMessage("You appear to be a bot. Please refresh the page and try again.");
      return;
    }
    e.preventDefault();
    handleLogin({ username: email, password: password })
      .then(() => {
        setLoginSuccess(true); // Update login success state on successful login
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <Grid container spacing={3} justify="center" alignItems="center" style={{ height: "100vh" }}>
      <Grid style={{ margin: "0 auto" }}>
        <Container style={{ textAlign: "center" }}>
          {errorMessage && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <div style={{ display: "none" }}>
              <input type="text" autoComplete="username" />
              <input type="password" autoComplete="current-password" />
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  style={{ fontFamily: "Avenir" }}
                  label="Username/Email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  name="email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className="d-flex justify-content-between align-items-center"></Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  required
                  variant="outlined"
                  name="password"
                  type="password"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Link
                to="/forgot-password"
                style={{
                  marginBottom: "8px",
                  textDecoration: "none",
                  fontSize: "0.8rem",
                  color: "#007BFF",
                }}
              >
                Forgot Password?
              </Link>
            </Grid>
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              fullWidth
              disabled={!email || !password || !recaptchaVerified}
              style={{ padding: "10px", marginTop: "16px" }}
            >
              Log in
            </LoadingButton>
            <Grid container justify="center" alignItems="center" style={{ marginTop: "16px" }}>
              <Typography variant="body2">Don't have an account yet?</Typography>
              <Link
                to="/signup"
                style={{
                  marginLeft: "8px",
                  textDecoration: "none",
                  color: "#007BFF",
                }}
              >
                Sign up here
              </Link>
            </Grid>
          </form>
        </Container>
      </Grid>
    </Grid>
  );
};
export default Login;
