/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { useNav, useRECAPTCHA } from "contexts";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { Modal } from "components";
import { signup } from "api/authentication";

const Form = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [validData, setValidData] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    title: "",
    bodyText: "",
    buttonText: "",
    titleText: "",
    onClose: () => {},
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const { recaptchaVerified, setRecaptchaAction } = useRECAPTCHA();
  const { navigate } = useNav();

  useEffect(() => {
    setRecaptchaAction("signup");
  }, []);

  const navigateToLogin = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showConfirmPasswordError || !validData) {
      setModalSettings({
        title: "Error",
        bodyText: "Please make sure all fields are filled out correctly.",
        buttonText: "Got it!",
        onClose: () => {},
      });
      setShowModal(true);
      return;
    } else if (!recaptchaVerified) {
      setModalSettings({
        title: "Error",
        bodyText: "If you are not a bot, please refresh the page and try again.",
        buttonText: "Got it!",
        onClose: () => {},
      });
      setShowModal(true);
      return;
    }
    signup({
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
    })
      .then((response) => {
        setModalSettings({
          title: "Success",
          bodyText: `Your account has been created successfully. Please check your email (${userData.email}) for an activation link.`,
          buttonText: "Got it!",
          onClose: navigateToLogin,
        });
        setShowModal(true);
      })
      .catch((error) => {
        // data.response.message says whats wrong
        setModalSettings({
          title: "Error",
          bodyText: error.response.data.message,
          buttonText: "Got it!",
          onClose: () => {
            setShowModal(false);
          },
        });
        setShowModal(true);
      });
  };
  useEffect(() => {
    if (userData.password !== "" && userData.confirmPassword !== "" && userData.password !== userData.confirmPassword) {
      setShowConfirmPasswordError(true);
    } else {
      setShowConfirmPasswordError(false);
    }
    setValidData(
      userData.password.length > 5 &&
        userData.confirmPassword === userData.password &&
        userData.firstName !== "" &&
        userData.lastName !== "" &&
        userData.email !== "" &&
        userData.email.includes("@"),
    );
  }, [userData.password, userData.confirmPassword, userData.firstName, userData.lastName, userData.email]);
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
          }}
          gutterBottom
          color={"text.secondary"}
          fontWeight={700}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Sign Up to Build Some Quizzes
        </Typography>
        <Typography color="text.secondary">Create a quick account to get started.</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              onChange={(e) => {
                setUserData({
                  ...userData,
                  firstName: e.target.value,
                });
              }}
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              onChange={(e) => {
                setUserData({
                  ...userData,
                  lastName: e.target.value,
                });
              }}
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              onChange={(e) => {
                setUserData({
                  ...userData,
                  email: e.target.value,
                });
              }}
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              onChange={(e) => {
                setUserData({
                  ...userData,
                  password: e.target.value,
                });
              }}
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
            />
          </Grid>
          {showConfirmPasswordError && (
            <Grid item xs={12}>
              <Alert severity="error">Oops! Your passwords don't match!</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Confirm your password (just to be sure)
            </Typography>
            <TextField
              label="Password *"
              onChange={(e) => {
                setUserData({
                  ...userData,
                  confirmPassword: e.target.value,
                });
              }}
              variant="outlined"
              name={"confirmpassword"}
              type={"password"}
              fullWidth
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link component={"a"} color={"primary"} href={"/login"} underline={"none"}>
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                disabled={showConfirmPasswordError || !validData}
              >
                Sign up
              </Button>
            </Box>
          </Grid>
          <Grid item container xs={12} justifyContent={"center"} alignItems={"center"}>
            <Typography variant={"subtitle2"} color={"text.secondary"} align={"center"}>
              By clicking "Sign up" button you agree with our{" "}
              <Link component={"a"} color={"primary"} href={"/terms"} underline={"none"}>
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      <Modal
        isOpen={showModal}
        onClose={modalSettings.onClose}
        titleText={modalSettings.title}
        buttonText={modalSettings.buttonText}
        bodyText={modalSettings.bodyText}
        modalType="alert"
      />
    </Box>
  );
};

export default Form;
