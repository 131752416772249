// Path: api/navigation.js
// Compare this snippet from src/pages/Stripe/PaymentComplete.js:

import axios from "axios";

export const getPrimaryNavigation = async () => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_PRIMARY_NAVIGATION}`;
    const headers = token ? { Authorization: `Token ${token}` } : {};
    const response = await axios.get(apiUrl, { headers });
    return response.data.navigation;
  } catch (error) {
    console.error("Error checking token", error);
    throw error;
  }
};
