let primaryRed = "rgb(255, 81, 81)";
let primaryBlue = "rgb(77, 164, 255)";
let primaryGreen = "rgb(0, 255, 128)";
const darken = (color, amount) => {
  // implement darkening of color.

  // Split the color into RGB components
  let red = color.substring(4, color.indexOf(","));
  let green = color.substring(color.indexOf(",") + 2, color.lastIndexOf(","));
  let blue = color.substring(color.lastIndexOf(",") + 2, color.indexOf(")"));

  // Darken the color
  red = red * (1 - amount);
  green = green * (1 - amount);
  blue = blue * (1 - amount);

  return `rgb(${red}, ${green}, ${blue})`;
};

const lighten = (color, amount) => {
  // Split the color into RGB components
  let red = color.substring(4, color.indexOf(","));
  let green = color.substring(color.indexOf(",") + 2, color.lastIndexOf(","));
  let blue = color.substring(color.lastIndexOf(",") + 2, color.indexOf(")"));

  // Lighten the color
  red = red * (1 + amount);
  green = green * (1 + amount);
  blue = blue * (1 + amount);

  return `rgb(${red}, ${green}, ${blue})`;
};
export const light = {
  alternate: {
    main: "#f7faff",
    dark: "#edf1f7",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: primaryBlue,
    light: lighten(primaryBlue, 0.5),
    dark: darken(primaryBlue, 0.5),
    contrastText: "#fff",
  },
  secondary: {
    main: primaryRed,
    light: lighten(primaryRed, 0.5),
    dark: darken(primaryRed, 0.5),
    contrastText: "#fff",
  },
  tertiary: {
    main: primaryGreen,
    light: lighten(primaryGreen, 0.5),
    dark: darken(primaryGreen, 0.5),
    contrastText: "#fff",
  },
  text: {
    primary: darken(primaryBlue, 0.9),
    secondary: darken(primaryBlue, 0.7),
    grey: "#6b6b6b",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#ffffff",
    default: "#ffffff",
    level2: "#f5f5f5",
    level1: "#ffffff",
  },
};

export const dark = {
  alternate: {
    main: "#1a2138",
    dark: "#151a30",
  },
  cardShadow: "rgba(0, 0, 0, .11)",
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "dark",
  primary: {
    main: "#1976d2",
    light: "#2196f3",
    dark: "#0d47a1",
    contrastText: "#fff",
  },
  secondary: {
    light: "#FFEA41",
    main: "#FFE102",
    dark: "#DBBE01",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#EEEEEF",
    secondary: "#AEB0B4",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  background: {
    paper: "#222B45",
    default: "#222B45",
    level2: "#333",
    level1: "#2D3748",
  },
};
