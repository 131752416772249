import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { Container } from "components";
import { Rocket } from "@mui/icons-material";

const AboutStatement = () => {
  const theme = useTheme();
  return (
    <Container maxWidth={"md"}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" gutterBottom color={theme.palette.secondary.main} fontWeight={300}>
          Affordable, AI-powered quiz generation for all.
        </Typography>
        <Typography gutterBottom variant="h5" color={theme.palette.primary.dark}>
          Our small team is obsessed with making online education tools more accessible, affordable, and powerful. We'd
          like to introduce you to StudyRocket, an app that lets you easily tap into next-gen AI technology to build
          quizzes about anything you're interested in learning. <strong>In lightspeed.</strong>
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutStatement;
