import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Container from "components/Container";
import { Explore, Public, Rocket } from "@mui/icons-material";
import { useNav, useAuth, useSub } from "contexts";

const plans = [
  {
    title: "Explorer Plan",
    price: "$0",
    icon: <Explore />,
    href: "/subscribe/explorer",
    features: ["Create up to 50 questions per month", "Supported question types: multiple choice"],
    actionLabel: "Become an Explorer",
  },
  {
    title: "Orbiter Plan",
    price: "$7",
    icon: <Public />,
    href: "/subscribe/orbiter",
    features: [
      "Create up to 500 questions per month",
      "Supported question types: multiple choice, true/false, fill in the blank",
      "Share quizzes with others",
      "Drag-n-drop question organizer",
    ],
    actionLabel: "Become an Orbiter",
  },
  {
    title: "Voyager Plan",
    icon: <Rocket />,
    price: "$14",
    href: "/subscribe/voyager",
    features: [
      "Create up to 2000 questions per month",
      "Supported question types: multiple choice, true/false, fill in the blank, open-ended",
      "Share quizzes with others",
      "Drag-n-drop question organizer",
      "Practice/review mode to focus on areas where mistakes are made",
    ],
    actionLabel: "Become a Voyager",
  },
];

const Plans = () => {
  const theme = useTheme();
  const { navigate } = useNav();
  const [subscribedPlanName, setSubscribedPlanName] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(1);
  const { planDetails } = useSub();

  useEffect(() => {
    if (planDetails) {
      setSubscribedPlanName(planDetails.name);
    }
  }, [planDetails]);

  useEffect(() => {
    let map = {
      "Explorer Plan": 0,
      "Orbiter Plan": 1,
      "Voyager Plan": 2,
    };

    if (subscribedPlanName) {
      setHighlightedIndex(map[subscribedPlanName]);
    }
  }, [subscribedPlanName]);

  return (
    <Container>
      <Grid container spacing={4}>
        {plans.map((item, i) => {
          let isHighlighted = highlightedIndex === i;
          return (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                boxShadow={isHighlighted ? 4 : 0}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={4} display={"flex"} justifyContent={"space-between"}>
                    <Box display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"start"}>
                      <Typography variant={"h4"} fontWeight={800}>
                        {item.title}
                      </Typography>
                    </Box>

                    <Box display={"flex"} alignItems={"baseline"}>
                      <Typography
                        variant={"h4"}
                        color={isHighlighted ? theme.palette.secondary.main : theme.palette.primary.main}
                      >
                        <Box component={"span"} fontWeight={600}>
                          {item.price}
                        </Box>
                      </Typography>
                      <Typography variant={"subtitle2"} color={"text.secondary"}>
                        /mo
                      </Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box component={ListItem} disableGutters width={"auto"} padding={0}>
                          <Box component={ListItemAvatar} minWidth={"auto !important"} marginRight={2}>
                            <Box
                              component={Avatar}
                              bgcolor={isHighlighted ? theme.palette.secondary.main : theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                  {subscribedPlanName === item.title && isHighlighted ? (
                    <Button
                      onClick={() => {
                        navigate("/account-plan");
                      }}
                      size={"large"}
                      variant={"contained"}
                      color={"secondary"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      {item.icon}
                      <span>My Plan</span>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        navigate(item.href);
                      }}
                      size={"large"}
                      variant={"contained"}
                      color={isHighlighted ? "secondary" : "primary"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      {item.icon}
                      {item.actionLabel}
                    </Button>
                  )}
                </CardActions>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Plans;
