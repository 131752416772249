// Filename: MultiChoiceQuestion.js

import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import FeedbackSection from "components/Question/FeedbackSection";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import backendSubmitOpenEndedQuizQuestion from "util/backendSubmitOpenEndedQuizQuestion";

const OEQuestion = ({
  question,
  handleNext,
  singleQuestion,
  totalQuestions,
  currentIndex,
  totalCorrectAnswers,
  setTotalCorrectAnswers,
}) => {
  /* 
  question structure: 
    {
      "id": "0da39c8c-e3fc-40ab-825c-086c0b018bae",
      "quiz": "16f5604e-8fe9-42bf-9639-e51669798168",
      "q": "How do you think OpenID Connect could improve user experience on websites?",
      "ch": {
          "ans": "Your answer here"
      },
      "t": "oe",
      "created_at": "2024-09-15 22:02:10",
      "generated_from": {
          "topic": "openid connect",
          "wiki_article": null,
          "upload_pdf": null
      }
  }
  */
  const [answer, setAnswer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const { q: questionText } = question;
  const [correct, setCorrect] = useState(null);

  useEffect(() => {
    //whenever questionText changes, set answer to empty
    setAnswer("");
  }, [questionText]);

  const handleSubmitAnswer = async (ans) => {
    return await backendSubmitOpenEndedQuizQuestion({
      selectedAnswer: answer,
      setTotalCorrectAnswers,
      totalCorrectAnswers,
      setFeedback,
      setSubmittingAnswer,
      question,
      setCorrect,
    });
  };

  return (
    <div>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {questionText}
      </ReactMarkdown>

      {/* open ended question answer input */}
      <TextField
        label="Type your answer here..."
        variant="outlined"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        disabled={submittingAnswer || feedback !== ""}
        multiline
        rows={6}
        fullWidth
      />

      <FeedbackSection
        singleQuestion={singleQuestion}
        selectedAnswer={answer}
        setSelectedAnswer={setAnswer}
        handleNext={handleNext}
        feedback={feedback}
        setFeedback={setFeedback}
        totalQuestions={totalQuestions}
        currentIndex={currentIndex}
        handleSubmitAnswer={handleSubmitAnswer}
        submittingAnswer={submittingAnswer}
        correct={correct}
      />
    </div>
  );
};

export default OEQuestion;
