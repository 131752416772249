import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "components/Container";
import { Hero } from "./components";
import { GenerateQuizForm, QuizList } from "components";
import { useAuth, useSub, useNav } from "contexts";
import { getQuizzes } from "api/quiz";
import { MonthlyLimitsReached } from "components/MonthlyLimitsReached";

const RocketDash = () => {
  const { isAuthenticated, isAuthenticatedLoading } = useAuth();
  const { navigate } = useNav();
  const { currentMonthUsage, planDetails } = useSub();
  const [quizzes, setQuizzes] = useState([]);
  const [quizzesLoading, setQuizzesLoading] = useState(true);
  const [withinLimits, setWithinLimits] = useState(true);

  useEffect(() => {
    if (planDetails && currentMonthUsage) {
      setWithinLimits(
        currentMonthUsage.questions < planDetails.monthly_limits.questions &&
          currentMonthUsage.quizzes < planDetails.monthly_limits.quizzes,
      );
    }
  }, [currentMonthUsage, planDetails]);

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticatedLoading) {
      // confirmed unauthenticated
      navigate("/login");
    } else if (isAuthenticated) {
      // manage quizzes state here because generateQuizForm needs to be
      // able to update the list of quizzes and QuizList needs to be able
      // to display the list of quizzes
      getQuizzes()
        .then((data) => {
          setQuizzes(data);
          setQuizzesLoading(false);
        })
        .catch((error) => {
          console.error("Error getting quizzes", error);
          setQuizzesLoading(false);
        });
    }
  }, [isAuthenticated, isAuthenticatedLoading]);
  if (!planDetails || !currentMonthUsage) {
    return null; // or a loading spinner
  }
  return (
    <Box>
      <Hero />
      <Container maxWidth={"lg"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          marginBottom={4}
          alignItems={"center"}
          gap={2}
          width={"100%"}
        >
          {withinLimits ? (
            <GenerateQuizForm setQuizzes={setQuizzes} />
          ) : (
            <MonthlyLimitsReached planDetails={planDetails} currentMonthUsage={currentMonthUsage} />
          )}
          <QuizList quizzes={quizzes} setQuizzes={setQuizzes} quizzesLoading={quizzesLoading} />
        </Box>
      </Container>
    </Box>
  );
};

export default RocketDash;
