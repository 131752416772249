import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      logAction: props.logAction,
      onRetry: props.onRetry,
      hasError: false,
    };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true, errorMessage: error.message });
    this.state.logAction(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container component="main" maxWidth="xl" sx={{ mt: 8, mb: 4, height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2, color: "var(--color-orange-primary)" }} />
            <Typography variant="h5" gutterBottom>
              Oops! Something went wrong.
            </Typography>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
