import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNav } from "contexts";
import { LoadingScreen } from "components/Loading";
import { getQuiz } from "api/quiz";
import { Container } from "components";
import { Box, Button, Card } from "@mui/material";
import { Quiz } from "components";

const QuizPage = () => {
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const { navigate } = useNav();

  useEffect(() => {
    if (quizId) {
      getQuiz({ quizId })
        .then((data) => {
          setQuiz(data);
        })
        .catch((error) => {
          console.error("Error getting quiz", error);
        });
    }
  }, [quizId]);

  if (!quiz) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={"md"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Quiz quiz={quiz} />
        <Card sx={{ padding: 2, marginTop: 2, boxShadow: "none", display: "flex", justifyContent: "end" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/rocketdash");
            }}
          >
            Back to Rocket Dash
          </Button>
        </Card>
      </Box>
    </Container>
  );
};

export default QuizPage;
