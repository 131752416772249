import React, { useEffect, useState } from "react";
import { useNav } from "contexts";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const NavItem = ({ title, items, icon }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState("");
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);
  const { IconMapper } = useNav();

  const hasActiveLink = () => items.find((i) => i.href === activeLink);

  return (
    <Box>
      <Accordion disableGutters elevation={0} sx={{ backgroundColor: "transparent" }} expanded={expanded}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" sx={{ padding: 0 }}>
          <Button
            sx={{
              fontWeight: hasActiveLink() ? 600 : 400,
              justifyContent: "flex-start",
            }}
            color="primary"
            fullWidth
            size="small"
            variant="text"
            startIcon={<IconMapper iconName={icon}></IconMapper>}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {title}
          </Button>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={1}>
            {items.map((p, i) => (
              <Grid item key={i} xs={12}>
                <Button
                  size={"small"}
                  variant="text"
                  component={"a"}
                  color="secondary"
                  href={p.href}
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    color: activeLink === p.href ? theme.palette.primary.main : theme.palette.secondary.main,
                    backgroundColor: activeLink === p.href ? alpha(theme.palette.primary.main, 0.1) : "transparent",
                    fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  {p.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClose: PropTypes.func,
};

export default NavItem;
