import React, { useState, useEffect } from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { Article } from "@mui/icons-material";
import { searchWikipedia } from "api/searchWikipedia";
import WikipediaSuggestion from "./WikipediaSuggestion";
import { LoadingComponent } from "components/Loading";

const WikipediaAutoComplete = ({ setWikipediaArticle }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState("");
  const [wikiSearchLoading, setWikiSearchLoading] = useState(false);

  // avoid fetching data on every keystroke; fetch data 1200ms after user stops typing
  useEffect(() => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }

    const timeout = setTimeout(() => {
      if (input.length > 0) {
        setWikiSearchLoading(true);
        searchWikipedia({ query: input })
          .then((data) => {
            setSuggestions(data);
            setWikiSearchLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setWikiSearchLoading(false);
          });
      } else {
        setSuggestions([]);
      }
    }, 1200);

    return () => clearTimeout(timeout);
  }, [input]);

  return (
    <Box
      marginBottom={2}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      gap={2}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        gap={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Article color="secondary" />
        <TextField
          fullWidth
          size="small"
          label="Search Wikipedia"
          type="url"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
      </Box>
      {suggestions.length > 0 && !wikiSearchLoading && (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          {suggestions.map((suggestion) => (
            <WikipediaSuggestion
              key={suggestion.pageid}
              suggestion={suggestion}
              onClick={() => {
                setWikipediaArticle(suggestion);
                setSuggestions([]);
              }}
            />
          ))}
        </Box>
      )}
      {wikiSearchLoading && (
        <Box display={"flex"} justifyContent={"center"} width={"100%"} height="100px" alignItems={"center"}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default WikipediaAutoComplete;
