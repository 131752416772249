// Component that represents a blank in a fill-in-the-blank question that can be dropped on.

import React from "react";
import { Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";

const DroppableBlank = ({ index, selectedAnswer, onClickFilledBlank = () => {} }) => {
  const filled = selectedAnswer && selectedAnswer[index] !== "";
  return (
    <Droppable droppableId={String(index)}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          onClick={(e) => {
            if (filled) {
              e.preventDefault();
              e.stopPropagation();
              onClickFilledBlank(index);
            }
          }}
          sx={{
            cursor: filled ? "pointer" : "default",
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 0.5,
            backgroundColor: snapshot.isDraggingOver ? "var(--color-primary)" : "var(--color-primary-light)",
            borderRadius: 1,
            display: "inline-block",
            marginLeft: 0.5,
            marginRight: 0.5,
            height: "25px",
            minWidth: "120px",
            marginBottom: -1,
            paddingTop: 0,
            textAlign: "center",
          }}
          variant="body1"
        >
          {filled ? selectedAnswer[index] : ""}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default DroppableBlank;
