// create ActivateAccount component that pulls userId and token from url params
import React, { useEffect, useState } from "react";
import { useNav } from "contexts";
import { useParams } from "react-router-dom";
import { activateAccount } from "api/authentication";
import { Typography, Button, Container, Alert, Grid, Box } from "@mui/material";

const ActivateAccount = () => {
  const { userId, token } = useParams();
  const [activated, setActivated] = useState(false);
  const [error, setError] = useState(null);
  const { setCurrentPage, navigate } = useNav();

  useEffect(() => {
    setCurrentPage("activate-account");
    activateAccount({ userId: userId, token: token })
      .then(() => {
        setActivated(true);
      })
      .catch((e) => {
        console.error(e);
        setError("We failed to activate your account.");
      });
  }, []);

  if (activated) {
    return (
      <Container maxWidth={"md"}>
        <Box marginTop={3} marginBottom={3}>
          <Grid container spacing={3} marginTop={3}>
            <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
              <Alert severity="success">
                <Typography variant="p" fontWeight={"bold"}>
                  Account activated! You can now login to your account.
                </Typography>
              </Alert>
            </Grid>
            <Grid item container xs={12} justifyContent={"center"} alignItems={"center"}>
              <Button variant="contained" color="primary" onClick={() => navigate("/login")}>
                Log In
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  } else if (error) {
    return (
      <Container maxWidth={"md"}>
        <Box marginTop={3} marginBottom={3}>
          <Alert
            severity="error"
            sx={{
              marginTop: 3,
            }}
          >
            <Typography variant="p" fontWeight={"bold"}>
              {error}
            </Typography>{" "}
            <br />
          </Alert>
        </Box>
      </Container>
    );
  }
};

export default ActivateAccount;
