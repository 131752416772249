import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  // display a linear progress bar using the current index and total questions
  height: 25,
  borderRadius: 5,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.tertiary.main,
  },
}));
export default ProgressBar;
