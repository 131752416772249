import React from "react";
import StudyRocketHeaderLogo from "img/headerlogo.png";
import { Typography, Box, useTheme } from "@mui/material";
import { useNav } from "contexts";

const HomeBrand = ({ height = 50, width = 50, fontVariant = "h4" }) => {
  const { navigate } = useNav();
  const theme = useTheme();
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginRight={2} gap={1}>
      <img src={StudyRocketHeaderLogo} alt="studyrocket.ai logo" width={`${width}`} height={`${height}`} />
      <Typography
        variant={fontVariant}
        style={{
          display: "flex",
          cursor: "pointer",
          color: "#004c00",
          fontWeight: 700,
          fontFamily: '"Inter",sans-serif',
          background: "linear-gradient(180deg, transparent 82%, rgba(0, 100, 100, 0.3) 0%)",
        }}
        onClick={() => navigate("/")}
      >
        <span style={{ color: theme.palette.primary.main }}>studyrocket</span>
        <span style={{ color: theme.palette.secondary.main }}>.ai</span>
      </Typography>
    </Box>
  );
};

export default HomeBrand;
