// Filename: MultiChoiceQuestion.js

import React, { useState } from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FeedbackSection from "components/Question/FeedbackSection";
import frontendSubmitQuizQuestion from "util/frontendSubmitQuizQuestion";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const TRFQuestion = ({
  question,
  handleNext,
  singleQuestion,
  totalQuestions,
  currentIndex,
  totalCorrectAnswers,
  setTotalCorrectAnswers,
}) => {
  /* question structure: 
	{
    "id": "4e177699-f14a-4fd8-ba5b-b5a1aaee1594",
    "quiz": "40c923f8-ca86-4039-af90-1868743afd70",
    "q": "Stand-up comedy is performed in front of an audience.",
    "ch": {
        "cor": "true",
        "exp": "Stand-up comedy is a performance art in which a comedian speaks in front of a live audience."
    },
    "t": "tf",
    "created_at": "2024-09-15 21:42:15",
    "generated_from": {
        "topic": "stand up comedy",
        "wiki_article": null,
        "upload_pdf": null
    }
}

		or 

		{
    "id": "4e177699-f14a-4fd8-ba5b-b5a1aaee1594",
    "quiz": "40c923f8-ca86-4039-af90-1868743afd70",
    "q": "Stand-up comedy is performed on the international space station.",
    "ch": {
        "cor": "false",
        "exp": "Stand-up comedy is a performance art in which a comedian speaks in front of a live audience, usually... not in space."
    },
    "t": "tf",
    "created_at": "2024-09-15 21:42:15",
    "generated_from": {
        "topic": "stand up comedy",
        "wiki_article": null,
        "upload_pdf": null
    }
}
    */
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");
  const handleSubmitAnswer = async (selectedAnswer) => {
    const correct = selectedAnswer.answer === question.ch.cor;
    return await frontendSubmitQuizQuestion({
      selectedAnswer: selectedAnswer,
      setTotalCorrectAnswers: setTotalCorrectAnswers,
      totalCorrectAnswers: totalCorrectAnswers,
      setFeedback: setFeedback,
      setSubmittingAnswer: setSubmittingAnswer,
      correct: correct,
      question: question,
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            gap: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
            {question.q}
          </ReactMarkdown>

          <ToggleButtonGroup
            onChange={(e) => {
              setSelectedAnswer({
                answer: e.target.value,
                feedback: question.ch.exp,
              });
            }}
            disabled={submittingAnswer || feedback !== ""}
            color="primary"
            label="Your answer"
            value={selectedAnswer?.answer}
            exclusive
          >
            <ToggleButton value="true">True</ToggleButton>
            <ToggleButton value="false">False</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      <FeedbackSection
        singleQuestion={singleQuestion}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
        handleNext={handleNext}
        feedback={feedback}
        setFeedback={setFeedback}
        totalQuestions={totalQuestions}
        currentIndex={currentIndex}
        handleSubmitAnswer={handleSubmitAnswer}
        submittingAnswer={submittingAnswer}
        correct={selectedAnswer?.answer === question.ch.cor}
      />
    </Box>
  );
};

export default TRFQuestion;
