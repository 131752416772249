import React from "react";
import { useNav } from "contexts";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Home } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import HomeBrand from "../HomeBrand";

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { navigate } = useNav();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <HomeBrand height={30} width={30} fontVariant={"h6"} />
          <Box display="flex" flexWrap={"wrap"} alignItems={"center"}>
            <Box marginTop={1} marginRight={2}>
              <Button onClick={() => navigate("/")} variant="contained" aria-label="Home" startIcon={<Home />}>
                Home
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography align={"center"} variant={"subtitle2"} color="text.secondary" gutterBottom>
          &copy; Study Rocket. 2024. All rights reserved.
        </Typography>
        <Typography align={"center"} variant={"caption"} color="text.secondary" component={"p"}>
          When you visit or interact with our sites, services or tools, we or our authorised service providers may use
          cookies for storing information to help provide you with a better, faster and safer experience and for
          marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
