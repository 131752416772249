// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from "react";
import { assertIsAuthenticated, login, logout } from "api/authentication";

export const AuthContext = createContext();
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null); // Authenticated user
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Is user authenticated?
  const [isAuthenticatedLoading, setIsAuthenticatedLoading] = useState(true); // Is authentication

  const handleLogin = async ({ username, password }) => {
    try {
      const data = await login({ username, password });
      setAuthUser(data.user);
      setIsAuthenticated(true);
      setIsAuthenticatedLoading(false);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = async () => {
    let response = await logout();
    setIsAuthenticated(false);
    // Remove all localStorage items with prefix 'studyrocket-'
    for (let i = localStorage.length - 1; i >= 0; i--) {
      let key = localStorage.key(i);
      if (key.startsWith("studyrocket-")) {
        localStorage.removeItem(key);
      }
    }
    return response;
  };

  useEffect(() => {
    assertIsAuthenticated()
      .then((isAuth) => {
        setIsAuthenticated(isAuth);
        setIsAuthenticatedLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsAuthenticatedLoading(false);
      });
  }, []);
  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        isAuthenticated,
        setIsAuthenticated,
        isAuthenticatedLoading,
        setIsAuthenticatedLoading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
