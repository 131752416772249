import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MaintenanceModeImage from "images/maintenance.webp";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "components/Container";

const MaintenanceMode = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} position={"relative"}>
          <Box width={1} order={{ xs: 2, md: 1 }} display={"flex"} alignItems={"center"}>
            <Container>
              <Box>
                <Typography
                  variant="h2"
                  component={"h2"}
                  align={isMd ? "left" : "center"}
                  sx={{
                    fontWeight: 300,
                  }}
                >
                  UNDER MAINTENANCE
                </Typography>
                <Typography variant="h6" component="p" color="text.secondary" align={isMd ? "left" : "center"}>
                  StudyRocket is currently under maintenance. We're working hard to improve our website and we'll be
                  back soon.
                  <br />
                  {/* TODO: add a link to a status feed on e.g., twitter after creating it */}
                </Typography>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: "0 0 100%", md: "0 0 50%" },
              position: "relative",
              maxWidth: { xs: "100%", md: "50%" },
              order: { xs: 1, md: 2 },
              minHeight: { xs: "auto", md: "calc(100vh)" },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: "50vw" },
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    left: "0%",
                    width: 1,
                    height: 1,
                    position: { xs: "relative", md: "absolute" },
                    clipPath: {
                      xs: "none",
                      md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                    },
                    shapeOutside: {
                      xs: "none",
                      md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "auto", md: 1 },
                      "& img": {
                        objectFit: "cover",
                      },
                    }}
                  >
                    <Box
                      component={"img"}
                      loading="lazy"
                      src={MaintenanceModeImage}
                      height={{ xs: "auto", md: 1 }}
                      maxHeight={{ xs: 300, md: 1 }}
                      width={1}
                      maxWidth={1}
                      sx={{
                        filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MaintenanceMode;
