import React from "react";
import { useNav } from "contexts";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import HomeBrand from "layouts/Main/components/HomeBrand";

import NavItem from "./components/NavItem";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { navigate, IconMapper } = useNav();
  const { mode } = theme.palette;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <HomeBrand height={50} width={50} fontVariant={"h4"} />
      </Box>
      <Box paddingX={2} paddingY={2}>
        {pages.map((page, i) =>
          page.items && page.items.length > 0 ? (
            <Box key={i}>
              <NavItem title={page.title} items={page.items} icon={page.icon} />
            </Box>
          ) : (
            <Box marginTop={2} key={i}>
              <Button
                sx={{ justifyContent: "flex-start" }}
                size={"small"}
                variant="text"
                fullWidth
                startIcon={<IconMapper iconName={page.icon} />}
                onClick={() => {
                  navigate(page.href);
                }}
              >
                {page.title}
              </Button>
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
