// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from "react";
import { getUserCurrentMonthUsage, getUserPlanDetails } from "api/subscription";
import { useAuth } from "contexts";

export const SubscriptionContext = createContext();
export const useSub = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [planDetailsLoading, setPlanDetailsLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState(null);
  const [currentMonthUsage, setCurrentMonthUsage] = useState(null);

  const updatePlanDetailsState = async () => {
    console.log("Fetching user plan details...");
    getUserPlanDetails()
      .then((data) => {
        console.log("User plan details fetched successfully", data);
        setPlanDetails(data);
        setPlanDetailsLoading(false);
      })
      .catch((error) => {
        setPlanDetails(null);
        setPlanDetailsLoading(false);
        console.error("Error getting user plan details", error);
      });
  };

  const updateUsageDetailsState = () => {
    console.log("Fetching user current month usage...");
    getUserCurrentMonthUsage()
      .then((data) => {
        console.log("User current month usage fetched successfully", data);
        setCurrentMonthUsage(data);
      })
      .catch((error) => {
        console.error("Error getting user usage details", error);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      updatePlanDetailsState().then(() => {
        updateUsageDetailsState();
      });
    }
  }, [isAuthenticated]);

  return (
    <SubscriptionContext.Provider
      value={{
        planDetails,
        setPlanDetails,
        currentMonthUsage,
        setCurrentMonthUsage,
        planDetailsLoading,
        updatePlanDetailsState,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
