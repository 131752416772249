import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Container from "components/Container";
import { Box, Button, Card, Typography } from "@mui/material";
import trumpetsAscend from "audio/trumpetsAscend.mp3";
import trumpetsDescend from "audio/trumpetsDescend.mp3";
const FinishScreen = ({ totalQuestions, totalCorrectAnswers, handleReset = () => {} }) => {
  const theme = useTheme();
  // use MUI components to
  // display a final feedback screen saying you got x of y questions correct
  // set background color to green if at least 90% correct, red if not
  // show a button to reset
  const [percentageCorrect, setPercentageCorrect] = useState(0);
  useEffect(() => {
    const percent = (totalCorrectAnswers / totalQuestions) * 100;
    setPercentageCorrect(percent);
  }, [totalCorrectAnswers, totalQuestions]);
  useEffect(() => {
    if (percentageCorrect) {
      if (percentageCorrect >= 90) {
        // play trumpets ascending
        const audio = new Audio(trumpetsAscend);
        audio.play();
      } else {
        // play trumpets descending
        const audio = new Audio(trumpetsDescend);
        audio.play();
      }
    }
  }, [percentageCorrect]);
  return (
    <Card
      bgcolor={theme.palette.primary.main}
      color={theme.palette.background.paper}
      className={"fade-in finish-screen"}
    >
      <Container paddingY={2} maxWidth={"95%"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 800,
              color: percentageCorrect >= 90 ? "var(--color-cyan-primary)" : "var(--color-orange-primary)",
            }}
          >
            {percentageCorrect.toFixed(0)}
            {"% "}
            {percentageCorrect >= 90 ? "🎉" : "😢"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 800,
              color: percentageCorrect >= 90 ? "var(--color-cyan-primary)" : "var(--color-orange-primary)",
            }}
          >
            You got {totalCorrectAnswers} of {totalQuestions} questions correct!{" "}
            {percentageCorrect >= 90 ? "Great job!" : "Want to try again?"}
          </Typography>
          <Button
            // show check answer button if feedback not currently displayed
            type={"button"}
            sx={{
              height: "fit-content",
              color: "white",
              width: "fit-content",
              margin: "1rem",
              fontSize: "1rem",
            }}
            color={percentageCorrect >= 90 ? "primary" : "secondary"}
            variant="contained"
            size="small"
            onClick={handleReset}
          >
            {percentageCorrect >= 90 ? "Again! Again!" : "Try again"}
          </Button>
        </Box>
      </Container>
    </Card>
  );
};

export default FinishScreen;
