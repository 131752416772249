// component listing out all available answer choices for a fill in the blank question
// and allowing the user to drag and drop them into the blanks

import React from "react";
import { Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import DraggableBlankFillerChip from "./DraggableBlankFillerChip";

const FIBBlankFillerChoices = ({ allChoices, onClickAnswerChoice }) => {
  // display a list of inline-block chips below the fill in the blank question
  // such that each time you click on a chip, it auto (animated) moves to
  // the next available blank in the question

  // shuffle all of the answer choices (correct and incorrect) so we can display
  // them in a random order. Do not remove duplicates because some
  // questions may have the same answer choices for different blanks.
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
      <Droppable droppableId="choices" direction="horizontal">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
          >
            {allChoices.map((choice, index) => (
              // choice format: "index-answerChoice" - used for answer's draggableId
              <DraggableBlankFillerChip
                key={choice}
                choice={choice}
                index={index}
                onClick={() => {
                  onClickAnswerChoice({
                    choice: choice,
                  });
                }}
              />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default FIBBlankFillerChoices;
