const correctFeedback = [
  "Great job!",
  "Nice!",
  "Good work!",
  "Keep it up!",
  "Well done!",
  "Excellent!",
  "Awesome!",
  "You got it!",
  "Fantastic!",
  "Perfect!",
  "You are doing great!",
];

const incorrectFeedback = ["Incorrect!", "Not quite!", "That's not it!", "Not quite right!"];

const getIncorrectFeedback = () => {
  return incorrectFeedback[Math.floor(Math.random() * incorrectFeedback.length)];
};

const getCorrectFeedback = () => {
  return correctFeedback[Math.floor(Math.random() * correctFeedback.length)];
};
export { getIncorrectFeedback, getCorrectFeedback };
