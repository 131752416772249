// src/api/authentication.js:
// This file contains the functions that make requests to the backend related to user authentication

import axios from "axios";

export const login = async ({ username, password }) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOGIN}`;
    const response = await axios.post(apiUrl, {
      username: username,
      password: password,
    });
    if (response.status === 200) {
      localStorage.setItem("studyrocket-token", response.data.token);
      localStorage.setItem("studyrocket-user", JSON.stringify(response.data.user));
    }
    return response.data;
  } catch (error) {
    console.error("Error logging in", error);
    throw error;
  }
};
export const logout = async () => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOGOUT}`,
      {},
      {
        headers: { Authorization: `Token ${token}` },
      },
    );

    // if response is 204 or response is 401 (unauthorized, which means the token is already invalid), clear local storage.
    if (response.status === 204 || response.status === 401) {
      return response.data;
    }
    throw new Error("Error logging out");
  } catch (error) {
    console.error("Error logging out", error);
    // clear local storage if there's an error, best to force new login.
    localStorage.clear();
    throw error;
  }
};

export const signup = async ({ firstName, lastName, email, password }) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_SIGNUP}`;
    const response = await axios.post(url, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error("Error signing up", error);
    throw error;
  }
};

export const assertIsAuthenticated = async () => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    if (!token) {
      return false;
    }
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CHECK_TOKEN}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    // if the token is valid, the response will be 200
    return response.status === 200;
  } catch (error) {
    // if invalid token, status
    console.error("Error checking token, clearing studyrocket-token from localStorage", error);
    localStorage.removeItem("studyrocket-token");
    return false;
  }
};

export const activateAccount = async ({ userId, token }) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ACTIVATE_ACCOUNT}${userId}/${token}/`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error activating account", error);
    throw error;
  }
};
