import { notifyFailure } from "toastNotifications";
import { submitOpenEndedQuizQuestion } from "api/quiz";

const backendSubmitOpenEndedQuizQuestion = async ({
  selectedAnswer = {},
  setTotalCorrectAnswers = () => {},
  totalCorrectAnswers = 0,
  setFeedback = () => {},
  setSubmittingAnswer = () => {},
  question = null,
  setCorrect = () => {},
}) => {
  console.log("backendSubmitQuizQuestion called");
  setSubmittingAnswer(true);
  // answer itself may have specific feedback; if not, use the default feedback
  return await submitOpenEndedQuizQuestion({
    question_id: question.id,
    answer: selectedAnswer,
  })
    .then((data) => {
      if (data.score >= 80) {
        setTotalCorrectAnswers(totalCorrectAnswers + 1);
        setCorrect(true);
      } else {
        setCorrect(false);
      }
      setFeedback(data.feedback);
      setSubmittingAnswer(false);
      return selectedAnswer;
    })
    .catch((error) => {
      console.error("Error submitting answer", error);
      notifyFailure("Error submitting answer");
    });
};

export default backendSubmitOpenEndedQuizQuestion;
