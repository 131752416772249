import axios from "axios";

export const searchWikipedia = async ({ query }) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_SEARCH_WIKIPEDIA}?search_query=${query}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    const pages = response.data.query.pages || [];
    // pages are id: {...}, id: {...}, etc.
    // we want to return an array of objects
    // so we map over the pages object and return the value of each key
    const results = Object.keys(pages).map((key) => pages[key]);
    return results;
  } catch (error) {
    console.error("Error searching Wikipedia", error);
    throw error;
  }
};
