// create a NavigationContext.js file in the contexts folder and add the following code to it:

import React, { Suspense, createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { getPrimaryNavigation } from "api/navigation";
import { LoadingScreen } from "components/Loading";

export const NavigationContext = createContext();
export const useNav = () => {
  return useContext(NavigationContext);
};
export const NavigationProvider = ({ children }) => {
  const [pages, setPages] = useState([]);
  const { isAuthenticatedLoading } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState("");
  const navigate = useNavigate();

  // Dynamically import icons from @mui/icons-material
  // backend defines simple icon names like "home", "user", "user-plus", etc.
  // here, we map those names to the actual icon components
  const icons = {
    home: React.lazy(() => import("@mui/icons-material/Home")),
    user: React.lazy(() => import("@mui/icons-material/Person")),
    "user-plus": React.lazy(() => import("@mui/icons-material/PersonAdd")),
    "user-edit": React.lazy(() => import("@mui/icons-material/Edit")),
    "sign-in": React.lazy(() => import("@mui/icons-material/Login")),
    "sign-out": React.lazy(() => import("@mui/icons-material/Logout")),
    info: React.lazy(() => import("@mui/icons-material/Info")),
    phone: React.lazy(() => import("@mui/icons-material/Phone")),
    rocket: React.lazy(() => import("@mui/icons-material/Rocket")),
  };

  const IconMapper = ({ iconName }) => {
    const IconComponent = icons[iconName];
    return IconComponent ? (
      <Suspense>
        <IconComponent />
      </Suspense>
    ) : null;
  };

  useEffect(() => {
    if (!isAuthenticatedLoading) {
      getPrimaryNavigation()
        .then((navigation) => {
          setPages(navigation);
        })
        .catch((e) => {
          console.error("Failed to get primary navigation: ", e);
        });
    }
  }, [isAuthenticatedLoading]);

  return pages.length > 0 ? (
    <NavigationContext.Provider value={{ pages, setPages, navigate, setCurrentPage, IconMapper }}>
      {children}
    </NavigationContext.Provider>
  ) : (
    <LoadingScreen />
  );
};
export default NavigationProvider;
