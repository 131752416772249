const prettyDate = ({ dateString, short = true }) => {
  const date = new Date(dateString);
  // short = false Monday, Jan 18, 2022, 3:00:00 PM
  // short = true 1/18/2022
  const options = short
    ? { month: "numeric", day: "numeric", year: "numeric" }
    : { weekday: "long", month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export default prettyDate;
