// component representing a single answer choice that can
// be dragged and dropped into a blank (DroppableBlank) in a fill in the blank question

import React from "react";
import { Chip } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

const DraggableBlankFillerChip = ({ choice, index, onClick }) => {
  return (
    <Draggable draggableId={choice} index={index}>
      {(provided, snapshot) => (
        <Chip
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          label={choice.substring(choice.indexOf("-") + 1)}
          onClick={onClick}
          sx={{
            backgroundColor: "var(--color-secondary-light)",
            position: "relative",
            transition: "all 0.5s",
            opacity: snapshot.isDragging ? 0.5 : 1,
            margin: "0.5rem",
          }}
          size="medium"
        />
      )}
    </Draggable>
  );
};

export default DraggableBlankFillerChip;
