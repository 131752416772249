import React from "react";
import { useNav } from "contexts";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeBrand from "layouts/Main/components/HomeBrand";
import { NavItem } from "./components";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { IconMapper, navigate } = useNav();
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={1}>
      <HomeBrand height={50} width={50} fontVariant={"h4"} />
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        {pages.map((page, i) =>
          page.items && page.items.length > 0 ? (
            <Box key={i} marginLeft={4} marginTop={2}>
              <NavItem id={`${page.title} - ${i}`} title={page.title} items={page.items} icon={page.icon} />
            </Box>
          ) : (
            <Box marginTop={2} key={i} marginLeft={4}>
              <Button
                size={"small"}
                variant="text"
                fullWidth
                color="secondary"
                onClick={() => {
                  navigate(page.href);
                }}
                startIcon={<IconMapper iconName={page.icon} />}
              >
                {page.title}
              </Button>
            </Box>
          ),
        )}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"text"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.array,
  colorInvert: PropTypes.bool,
};

export default Topbar;
