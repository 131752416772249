import React from "react";
import { Hero, AboutStatement, FeatureCardsWithColorfullIconsAndLearnMoreLink } from "./components";
import { useSub, useNav, useAuth } from "contexts";
import { Box, Button, Typography } from "@mui/material";
import Rocket from "@mui/icons-material/Rocket";
import { Widgets } from "@mui/icons-material";

const Home = () => {
  const { isAuthenticated } = useAuth();
  const { navigate } = useNav();
  const { planDetails } = useSub();
  return (
    <Box>
      <Hero />
      {isAuthenticated && (
        <Box
          maxWidth={"md"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginX={"auto"}
          flexDirection={"row"}
          marginY={"3rem"}
          backgroundColor={"background.paper"}
          gap={3}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate("/rocketdash");
            }}
          >
            <Rocket sx={{ marginRight: 1 }} />
            Go to Rocket Dash
          </Button>
          {planDetails && planDetails.name != "Voyager Plan" && (
            <Button onClick={() => navigate("/plans")} variant="contained" color="primary">
              <Widgets sx={{ marginRight: 1 }} />
              Get More Features
            </Button>
          )}
        </Box>
      )}
      <AboutStatement />

      <FeatureCardsWithColorfullIconsAndLearnMoreLink />
    </Box>
  );
};

export default Home;
