// ReCAPTCHAContext - provide a context that automatically
// checks if the user is a bot or not using Google's reCAPTCHA service.
// Components inside the ReCAPTCHAContext provider can use the recaptchaVerified state to determine if the user is a bot or not.

import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { verifyRecaptcha } from "api/recaptcha";
import { notifyFailure } from "toastNotifications";

export const ReCAPTCHAContext = createContext();
export const useRECAPTCHA = () => {
  return useContext(ReCAPTCHAContext);
};
export const ReCAPTCHAProvider = ({ children }) => {
  const [recaptchaAction, setRecaptchaAction] = useState(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    // not sure what the action string should be
    const token = await executeRecaptcha(recaptchaAction);
    setRecaptchaToken(token);
    // reset the action string back to null so that we don't keep verifying the recaptcha
    setRecaptchaAction(null);
  }, [executeRecaptcha]);

  useEffect(() => {
    if (recaptchaToken) {
      verifyRecaptcha({ token: recaptchaToken })
        .then((success) => {
          if (success) {
            setRecaptchaVerified(true);
          } else {
            setRecaptchaVerified(false);
            notifyFailure("Please verify that you are not a robot");
          }
        })
        .catch((error) => {
          notifyFailure("Please verify that you are not a robot");
          console.error(error);
        });
    }
  }, [recaptchaToken]);

  // Trigger the verification as soon as the component is loaded.
  // Child component will set the action string.
  // once that is set, the recaptcha token will be generated.
  // this ensures we're only verifying the recaptcha when we need to.
  useEffect(() => {
    if (recaptchaAction) {
      handleReCaptchaVerify();
    }
  }, [handleReCaptchaVerify, recaptchaAction]);

  return (
    <ReCAPTCHAContext.Provider value={{ recaptchaVerified, setRecaptchaAction }}>{children}</ReCAPTCHAContext.Provider>
  );
};
export default ReCAPTCHAProvider;

// This code creates a context and a provider for the navigation menu. The provider uses the buildPrimaryNavigationMenu function to create the navigation menu based on the user's type and courses. It then provides the pages, loading, and navigate values to its children.
