import React, { useEffect } from "react";
import { useAuth, useNav } from "contexts";
import { getPrimaryNavigation } from "api/navigation";

const Logout = () => {
  // immediately logout the user
  const { handleLogout } = useAuth();
  const { setPages, navigate } = useNav();

  useEffect(() => {
    handleLogout().then(() => {
      getPrimaryNavigation().then((navigation) => {
        setPages(navigation);
        navigate("/");
      });
    }); // this immediately logs out the user and navigates to the home page
  }, []);
  return null; // no UI
};

export default Logout;
