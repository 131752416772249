// Filename: MultiChoiceQuestion.js

import React, { useEffect, useState } from "react";
import { Typography, FormControl, FormControlLabel, RadioGroup, Radio, Box, Card } from "@mui/material";
import FeedbackSection from "components/Question/FeedbackSection";
import shuffle from "util/shuffle";
import frontendSubmitQuizQuestion from "util/frontendSubmitQuizQuestion";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const MLTQuestion = ({
  question,
  handleNext,
  singleQuestion,
  totalQuestions,
  currentIndex,
  totalCorrectAnswers,
  setTotalCorrectAnswers,
}) => {
  /* 
  question structure: 
   {
      "t": "mlt",
      "q": "What is the capital of France?",
      "ch": {
          "cor": {
              "ans": "Paris",
              "fb": "Correct! The capital of France is Paris"
          },
          "inc": [
              {
              "ans": "London", "fb": "London is the capital of the UK."
              },
              {
              "ans": "Berlin", "fb": "Berlin is the capital of Germany."
              },
              {
              "ans": "Madrid", "fb": "Madrid is the capital of Spain."
              }
          ]
      }
  }, ...
  */
  const [feedback, setFeedback] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answerChoices, setAnswerChoices] = useState([]);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const {
    q: questionText,
    ch: { cor: correctAnswer, inc: incorrectAnswers },
  } = question;

  useEffect(() => {
    setAnswerChoices(shuffle([correctAnswer, ...incorrectAnswers]));
  }, [correctAnswer, incorrectAnswers]);

  const handleSubmitAnswer = async (selectedAnswer) => {
    return await frontendSubmitQuizQuestion({
      selectedAnswer: selectedAnswer,
      setTotalCorrectAnswers: setTotalCorrectAnswers,
      totalCorrectAnswers: totalCorrectAnswers,
      setFeedback: setFeedback,
      setSubmittingAnswer: setSubmittingAnswer,
      correct: selectedAnswer.ans === correctAnswer.ans,
      question: question,
    });
  };

  return (
    <div>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {questionText}
      </ReactMarkdown>

      <FormControl component="fieldset" sx={{ width: 1 }}>
        <RadioGroup value={selectedAnswer?.ans || ""} name="radio-buttons-group" sx={{ width: 1 }}>
          {answerChoices.map((item, i) => (
            <Card key={i} className="answer-choice" sx={{ width: 1, paddingX: { xs: 1, sm: 2 }, marginY: "3px" }}>
              <FormControlLabel
                value={item.ans}
                control={
                  <Radio
                    onClick={() => {
                      if (feedback === "") setSelectedAnswer(item);
                    }}
                  />
                }
                disabled={feedback != ""}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 1,
                      marginY: 0.5,
                    }}
                  >
                    <Box>
                      <Typography textAlign={"left"} fontWeight={300} variant={"body1"}>
                        {item.ans}
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        sx={{ display: "none" }}
                        className="feedback"
                        fontWeight={300}
                        variant={"body1"}
                      ></Typography>
                    </Box>
                  </Box>
                }
                sx={{
                  width: 1,
                  "& .MuiFormControlLabel-label": { width: 1 },
                }}
              />
            </Card>
          ))}
        </RadioGroup>
      </FormControl>
      <FeedbackSection
        singleQuestion={singleQuestion}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
        handleNext={handleNext}
        feedback={feedback}
        setFeedback={setFeedback}
        totalQuestions={totalQuestions}
        currentIndex={currentIndex}
        handleSubmitAnswer={handleSubmitAnswer}
        submittingAnswer={submittingAnswer}
        correct={selectedAnswer?.ans === correctAnswer.ans}
      />
    </div>
  );
};

export default MLTQuestion;
