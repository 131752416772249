// SignUp.js

import React, { useEffect } from "react";
import { useAuth, useNav } from "contexts";
import Box from "@mui/material/Box";
import { Container } from "components";
import Form from "./Form";

const SignUp = () => {
  const { isAuthenticated } = useAuth();
  const { setCurrentPage, navigate } = useNav();

  useEffect(() => {
    setCurrentPage("sign-up");
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} position={"relative"}>
      <Box width={1} order={{ xs: 2, md: 1 }} display={"flex"} alignItems={"center"} marginRight={{ xs: 0, md: 4 }}>
        <Container>
          <Form />
        </Container>
      </Box>
    </Box>
  );
};

export default SignUp;
