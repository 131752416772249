import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNav } from "contexts";
import { Check, Close, Widgets } from "@mui/icons-material";
import {
  Grid,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Button,
  Avatar,
  CardContent,
  Box,
  Card,
} from "@mui/material";
import ConfirmCancelModal from "./ConfirmCancelModal";

const MyPlanDetails = ({ planDetails }) => {
  const theme = useTheme();
  const { navigate } = useNav();
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  return (
    <>
      <ConfirmCancelModal
        planDetails={planDetails}
        setShowCancelConfirmModal={setShowCancelConfirmModal}
        showCancelConfirmModal={showCancelConfirmModal}
      />
      <Box component={Card} height={1} display={"flex"} flexDirection={"column"} boxShadow={4}>
        <CardContent
          sx={{
            padding: 4,
          }}
        >
          <Box marginBottom={4} display={"flex"} justifyContent={"space-between"}>
            <Typography variant={"h4"}>
              <Box component={"span"} fontWeight={600}>
                {planDetails.name}
              </Box>
            </Typography>
            <Box display={"flex"} alignItems={"baseline"}>
              <Typography variant={"h4"} color={"primary"}>
                <Box component={"span"} fontWeight={600}>
                  ${planDetails.monthly_price_dollars}
                </Box>
              </Typography>
              <Typography variant={"subtitle2"} color={"text.secondary"}>
                /mo
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={1}>
            {Object.entries(planDetails.features).map(([feature, enabled], j) => {
              // split on _ and capitalize each word
              feature = feature
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
              return (
                <Grid item xs={12} key={j}>
                  <Box component={ListItem} disableGutters width={"auto"} padding={0}>
                    <Box component={ListItemAvatar} minWidth={"auto !important"} marginRight={2}>
                      <Box
                        component={Avatar}
                        bgcolor={enabled === 1 ? theme.palette.primary.main : theme.palette.secondary.main}
                        width={20}
                        height={20}
                        padding={"1rem"}
                      >
                        {enabled === 1 ? <Check /> : <Close />}
                      </Box>
                    </Box>
                    <ListItemText primary={feature} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <Box display={"flex"} justifyContent={"end"} gap={2} marginTop={4}>
            {
              // if not on max plan, allow upgrade
              !planDetails.name.includes("Voyager Plan") && (
                <Button onClick={() => navigate("/plans")} variant="contained" color="primary">
                  <Widgets sx={{ marginRight: 1 }} />
                  Get More Features
                </Button>
              )
            }
            {
              /* do not show cancel button for free / default explorer plan */
              !planDetails.name.includes("Explorer") && (
                <Button onClick={() => setShowCancelConfirmModal(true)} variant="outlined" color="error">
                  Cancel Subscription
                </Button>
              )
            }
          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default MyPlanDetails;
