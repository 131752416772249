import axios from "axios";

export const createStripePaymentIntent = async ({ items }) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CREATE_STRIPE_PAYMENT_INTENT}`;
    const response = await axios.post(
      apiUrl,
      { items },
      {
        headers: { Authorization: `Token ${token}` },
      },
    );
    if (response.status === 201) {
      return response.data.clientSecret;
    }
  } catch (error) {
    console.error("Error creating stripe payment intent", error);
    throw error;
  }
};

export const createCheckoutSession = async ({ plan }) => {
  console.log("Creating checkout session for plan", plan);
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CREATE_CHECKOUT_SESSION}`;
    const response = await axios.post(
      apiUrl,
      { plan: plan },
      {
        headers: { Authorization: `Token ${token}` },
      },
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error("Error creating checkout session", error);
    throw error;
  }
};

export const getCheckoutSessionStatus = async ({ sessionId, plan }) => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    let apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_CHECKOUT_SESSION_STATUS}${sessionId}/${plan}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error getting checkout session status", error);
    throw error;
  }
};

export const sendPaymentCompleteConfirmation = async () => {
  try {
    const token = localStorage.getItem("studyrocket-token");
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_SEND_PAYMENT_COMPLETE_CONFIRMATION}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error("Error sending payment complete confirmation", error);
    throw error;
  }
};
