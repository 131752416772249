import React, { useEffect, useCallback } from "react";
import { useNav, useAuth, useSub } from "contexts";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import "./Checkout.css";
import { createCheckoutSession } from "api/stripe";
import Container from "components/Container";
import { Rocket } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";

let stripePk =
  process.env.REACT_APP_STRIPE_ENVIRONMENT === "live"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;

const stripePromise = loadStripe(stripePk);

const Checkout = () => {
  // rendered at /subscribe
  const { plan } = useParams();
  const { navigate } = useNav();
  const { planDetails, planDetailsLoading } = useSub();
  const { isAuthenticated, isAuthenticatedLoading } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticatedLoading) {
      // If not authenticated, redirect to login
      navigate("/login");
    }
  }, [isAuthenticated, isAuthenticatedLoading]);

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return createCheckoutSession({ plan: plan })
      .then((data) => data.client_secret)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }, []);

  const options = { fetchClientSecret };
  if (isAuthenticatedLoading) {
    return null;
  }

  if (planDetails && planDetails.name.toLowerCase().includes(plan)) {
    return (
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            align="left"
            color="text.primary"
            marginBottom={1}
            textAlign={"left"}
            sx={{ fontWeight: 700 }}
          >
            You're already subscribed to the {planDetails.name}!
          </Typography>
          <Typography variant="p" color={"text.primary"} marginBottom={2} textAlign={"left"}>
            You have full access to generate quizzes using AI about any topic, article, or document you wish. Have fun
            studying!
          </Typography>
          <Button variant="contained" color="secondary" startIcon={<Rocket />} onClick={() => navigate("/rocketdash")}>
            Rocket Dash
          </Button>
        </Box>
      </Container>
    );
  }

  if (plan) {
    return (
      <Container maxWidth="md">
        <Box sx={{ textAlign: "center" }}>
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      </Container>
    );
  }
};

export default Checkout;
