import Container from "components/Container";
import { useTheme } from "@mui/material/styles";
import { Card } from "@mui/material";
import { MLTQuestion, TRFQuestion, OEQuestion, FIBQuestion } from "./types";
import "index.css";

const Question = ({
  question = {},
  handleNext = () => {},
  singleQuestion = false,
  totalQuestions = 0,
  currentIndex = 0,
  totalCorrectAnswers = 0,
  setTotalCorrectAnswers = () => {},
  isAnimating = false,
}) => {
  const theme = useTheme();

  const slideClass = isAnimating ? "fade-out" : "fade-in";
  return (
    <Card
      bgcolor={theme.palette.primary.main}
      color={theme.palette.background.paper}
      className={`${slideClass} question-card`}
    >
      <Container paddingY={2} maxWidth={"95%"}>
        {question.t && question.t === "mlt" && (
          <MLTQuestion
            question={question}
            handleNext={handleNext}
            singleQuestion={singleQuestion}
            totalQuestions={totalQuestions}
            currentIndex={currentIndex}
            totalCorrectAnswers={totalCorrectAnswers}
            setTotalCorrectAnswers={setTotalCorrectAnswers}
          />
        )}
        {question.t && question.t === "tf" && (
          <TRFQuestion
            question={question}
            handleNext={handleNext}
            singleQuestion={singleQuestion}
            totalQuestions={totalQuestions}
            currentIndex={currentIndex}
            totalCorrectAnswers={totalCorrectAnswers}
            setTotalCorrectAnswers={setTotalCorrectAnswers}
          />
        )}
        {question.t && question.t === "fib" && (
          <FIBQuestion
            question={question}
            handleNext={handleNext}
            singleQuestion={singleQuestion}
            totalQuestions={totalQuestions}
            currentIndex={currentIndex}
            totalCorrectAnswers={totalCorrectAnswers}
            setTotalCorrectAnswers={setTotalCorrectAnswers}
          />
        )}
        {question.t && question.t === "oe" && (
          <OEQuestion
            question={question}
            handleNext={handleNext}
            singleQuestion={singleQuestion}
            totalQuestions={totalQuestions}
            currentIndex={currentIndex}
            totalCorrectAnswers={totalCorrectAnswers}
            setTotalCorrectAnswers={setTotalCorrectAnswers}
          />
        )}
      </Container>
    </Card>
  );
};

export default Question;
