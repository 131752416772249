// ProtectedRoute.js
import React from "react";
import { useAuth } from "contexts";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingScreen } from "components/Loading";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isAuthenticatedLoading } = useAuth();
  const location = useLocation();
  if (isAuthenticatedLoading) {
    return <LoadingScreen />;
  } else if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
