import React from "react";
import { Box, Button, Typography } from "@mui/material";

const WikipediaSuggestion = ({ suggestion, onClick }) => {
  const isMobile = window.innerWidth < 768;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      boxShadow={2}
      marginY={0.5}
      padding={2}
      borderRadius={2}
      width={"100%"}
      bgcolor={"background.paper"}
    >
      <Box
        display={"flex"}
        flexDirection={isMobile ? "column" : "row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography variant="body1" textAlign={"left"} marginRight={"auto"}>
          <span style={{ fontWeight: "bold", textTransform: "uppercase", color: "var(--color-secondary)" }}>
            {suggestion.title}
          </span>
          <br />
          {suggestion.description || "No description available"}
        </Typography>
        <Box marginLeft={"auto"} marginTop={1} display={"flex"} gap={1} minWidth={"30%"} justifyContent={"end"}>
          <Button
            LinkComponent={"a"}
            variant="outlined"
            size="small"
            href={`https://en.wikipedia.org/wiki/${encodeURIComponent(suggestion.title.replace(/ /gi, "_"))}`}
            target="_blank"
          >
            Read More
          </Button>
          <Button variant="contained" size="small" onClick={onClick}>
            Use for Quiz
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WikipediaSuggestion;
