import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSub } from "contexts";
import { cancelSubscription } from "api/subscription";
import { notifyFailure, notifySuccess } from "toastNotifications";

const ConfirmCancelModal = ({ planDetails, setShowCancelConfirmModal, showCancelConfirmModal }) => {
  /* mui modal */
  const { updatePlanDetailsState } = useSub();
  const theme = useTheme();
  const modalCardStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: `2px ${theme.palette.primary.main} solid`,
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={showCancelConfirmModal}
      onClose={() => {
        setShowCancelConfirmModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalCardStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to cancel your subscription? You will lose access to {planDetails.name} features and
          fall back to the free Explorer Plan.
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"} marginTop={4} alignItems={"center"}>
          <Button variant="contained" color="primary" onClick={() => setShowCancelConfirmModal(false)}>
            No, keep {planDetails.name}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              cancelSubscription({ plan: planDetails.name.split(" ")[0].toLowerCase() })
                .then(() => {
                  setShowCancelConfirmModal(false);
                  notifySuccess("Subscription cancelled successfully");
                  updatePlanDetailsState();
                })
                .catch((error) => {
                  notifyFailure("Error cancelling subscription");
                });
            }}
          >
            Confirm Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmCancelModal;
